@import "../variables.scss";

.query-results-container {
	display: flex;
	flex-direction: row;
}

.download-button-container {
	text-align: end;
}


.status-element {
	display: flex;
	justify-content: flex-start;
}

.status-element-label {
	font-weight: bold;
	min-width: 125px;
}

.error-message {
	background-color: $light-gray1;
	padding: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	border-radius: 5px;
}