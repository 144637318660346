/* Import of Futura and Acumin-Pro fonts */
/* TODO: Do we want to self-host these? */
@import url("https://use.typekit.net/nja8nky.css");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-title;
  font-weight: $font-weight-medium;
}
