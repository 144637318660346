@import "../variables.scss";

.query-panel-container {
  display: flex;
  flex-direction: column;
}

.query-panel-top {
  flex: 1;
  display: flex;
  flex-direction: row;
  max-height: 440px;
  padding: 0 $pt-grid-size;
}

.query-panel-editor {
  flex: 1;
  margin-top: 10px;
  min-width: 400px;
}

.query-panel-schema {
  flex: 0 0 300px;
  overflow-y: scroll;
  margin-top: 10px;
}

.query-panel-schema .bp4-tree-node-content-1 {
  padding-left: 5px;
}

.query-panel-results {
  flex: 4;
  overflow-x: hidden;
  margin-top: 10px;
  padding: 0 $pt-grid-size;
}