.table-name,
.column-name {
    cursor: pointer;
}

.schema-tree-container {
    margin-top: 5px;
}

.tree-title {
    margin: 0px 0px 5px 10px;
    cursor: default;
    display: inline-block;
}

.help {
    float: right;
    margin-right: 5px
}