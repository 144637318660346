$font-family-title: "futura-pt", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$font-family-body: "acumin-pro", Helvetica, Arial, sans-serif;
$font-family-code: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;

$font-weight-light: 300;
$font-weight-book: 400;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-demibold: 600;
$font-weight-semibold: 600;
$font-weight-heavy: 700;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

$pt-app-background-color: #fbfbfb;
$pt-intent-primary: #0076be;
$pt-intent-success: #00ae42;
$pt-intent-warning: #ff671d;
$pt-intent-danger: #c40d3c;
$pt-font-family: $font-family-body;
$pt-font-family-monospace: $font-family-code;

@import "~@blueprintjs/core/lib/scss/variables.scss";
@import "~@blueprintjs/core/src/common/_variables-extended.scss";
