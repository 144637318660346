
.label {
    font-weight: bold;
    min-width: 150px;
}

.value {
    font-size: 1.15em;
}


.file-container{
    position: relative;
}

.file-copy-button{
    position: absolute;
    top: 10px;
    right: 10px;
}

#odbc-connection-string .cm-editor{
   //padding-top: 40px;
   //comment out until we bring back copy button
}

.expiration {
    margin-top: 5px;
}

.copy-button {
    display: none;
}